﻿@import 'variables';
@import 'mixins/buttons';

@mixin latoFont {
  font-family: Lato, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-display: swap;
}

@mixin defaultPadding {
  padding: 9px 32px 9px 16px;
}

@mixin bg-overlay($background: $bgOverlay) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $background;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin shadow() {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

@mixin shadow26() {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}

@mixin container($radius: $defaultRadius, $backgroundColor: $bgContainer) {
  @include shadow();
  background-color: $backgroundColor;
  object-fit: contain;
  border-radius: $radius;
  position: relative;
  z-index: 2;
  padding: 15px;
  margin: 0px;
  height: 100%;
  min-height: auto;
}

/* Not everyone likes this so I added it as mixin to easy remove */
@mixin cursorDefault() {
  cursor: default;
}
