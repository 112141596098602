// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {

  ion-textarea,
  ion-input {
    &[variant="secondary"] {
      --background: var(--aaa-color-light-base);
      --border-color: var(--aaa-color-secondary);
      --border-radius: 12px;
      --placeholder-color: var(--aaa-color-input-danger);
      --highlight-color: var(--text-and-icons-text-subtle, #334155);
      --highlight-color-focused: var(--text-and-icons-text-subtle, #334155);
      color: var(--text-and-icons-text-subtle, #334155);
      font-family: var(--ion-font-family) sans-serif;
      font-size: 14px;
      font-weight: 400;
      min-height: 53px;

      &~app-form-helper {
        margin-left: 8px;
        position: absolute;
      }

      &.ngrx-forms-invalid.ngrx-forms-touched {
        --highlight-color: var(--aaa-color-input-danger);
        --highlight-color-focused: var(--aaa-color-input-danger);
        --highlight-color-invalid: var(--aaa-color-input-danger);
        --highlight-color-valid: var(--aaa-color-input-danger);
        --placeholder-color: var(--aaa-color-input-danger);
        --border-color: var(--aaa-color-input-danger);
        --box-shadow: 0px 0px 8px 0px rgba(181, 29, 9, 0.25);
        color: var(--aaa-color-input-danger);
      }
    }
  }
}