﻿@import 'variables';
@import 'mixins';
@import 'ion-variables';
@import 'ion-inputs';

.hide-xs {
  @media screen and (max-width: $break-xsmall) {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.hide-sm,
.hide-mobile,
.show-desktop {
  @media screen and (max-width: $break-small) {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.hide-desktop,
.show-mobile {
  @media screen and (min-width: ($break-small + 1 )) {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.hide-md {
  @media screen and (min-width: $break-medium + 1) {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.hide-lg {
  @media screen and (min-width: $break-large + 1) {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.hide-xl {
  @media screen and (min-width: $break-xlarge + 1) {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.data-group {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 1px;

  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    height: 36px;
    width: 36px;
  }

  &_data {
    width: 100%;
    text-align: left;
    > label {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.58px;
    }
    > span,
    .large {
      font-size: 18px;
      font-weight: normal;
      text-transform: none;
    }
  }

  &_data-center {
    text-align: center;
  }

  &_icon-end {
    padding-left: 12px;
    .data-group_icon {
      order: 1;
      margin-left: 11px;
      margin-right: 0;
    }
  }

  &.no-padding {
    &.data-group_icon-end {
      padding-left: 12px !important;
    }
  }
}

.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.tiny-text {
  @include latoFont();
  font-size: 10px;
}

.small-text {
  @include latoFont();
  font-size: 12px;
}

.text-error {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
}

.mobile-small {
  @include latoFont();
  font-size: 14px;

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}

.normal-text {
  @include latoFont();
  font-size: 14px;
}

.large-text {
  @include latoFont();
  font-size: 16px;
}

.huge-text {
  @include latoFont();
  font-size: 30px;
}

.small-title {
  @include latoFont();
  font-size: 18px;
  margin-bottom: 8px;
}

.medium-title {
  @include latoFont();
  font-size: $mediumBigFontSize
}

.large-title {
  @include latoFont();
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 0;
  color: inherit;
  line-height: inherit;
  font-weight: 500;

  @media screen and (max-width: $break-small) {
    font-size: 24px;
    margin-bottom: 14px;
  }
}

.huge-title {
  @include latoFont();
  font-size: 36px;
  margin-bottom: 32px;

  @media screen and (max-width: $break-small) {
    font-size: 30px;
    margin-bottom: 14px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $primaryColorLighter;
  margin-bottom: 29px;
}

.light-divider {
  width: 100%;
  height: 1px;
  border-bottom: 0.5px solid #80808080;
  box-sizing: border-box;
}

.vertical-divider {
  height: 30px;
  width: 1px;
  background-color: $primaryColorLighter;
  margin: 0px 9px;
}

.small-pad {
  padding: 3px 9px !important;
}

.short-button {
  min-height: 30px !important;
  padding: 6px 12px !important;
  font-size: 12px;
}

.app-square-textbox {
  max-height: 50px;
  min-height: 50px;
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
}

.large-button {
  min-height: 54px;
  margin-bottom: 15px;
  padding: 5px 12px;
  letter-spacing: 0.44px;
}

button::-moz-focus-inner {
  border: 0;
}

.generic-dialog-link-button {
  display: block;
  margin: 15px -8px !important;
  color: $accentColor !important;
  background-color: $primaryColor !important;
  text-transform: uppercase !important;
  &.inverted {
    color: $primaryColor !important;
    background-color: $accentColor !important;
    border: 1px solid $primaryColor;
  }
}

.base-btn-size {
  min-width: 220px;
  height: 50px;
}

input {
  &:focus {
    outline: black 2px solid;
  }
}

.pointer {
 cursor: pointer;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// for more context check: https://stackoverflow.com/a/16255670
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
